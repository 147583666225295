/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import { Link } from "react-router-dom";

const yearlyReport =[
  "General",
  2025,
  2024,
  2023,
  2022,
  2021,
  2020,
  2019,
  2018,
]

/*
  Get all pdf in selected file
*/
function importAll(r) {
  return r.keys().map(r);
}


/*
  Filter selected pdf by specific category.
*/
function getPDFs(pdfs, company, year) {
  let companyPDFs = pdfs.filter((pdf) => pdf.default.includes(company));
  let yearPDFs = companyPDFs.filter((companyPDF) =>
    companyPDF.default.includes(year)
  );

  return yearPDFs;
}

const Downloadable = () => {
  const pdfs = importAll(require.context("../files", false, /\.(pdf)$/));

  return (
    <>
      <div className="services-area downloadable-area mt-100 text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <h2>PT. Panasia Indo Resouces Tbk.</h2>
              <div className="downloadable-accordian-wrapeer">
                <div id="accordion-hdtx">
                  {
                    yearlyReport.map((year, index) => 
                    {
                      var files = getPDFs(pdfs, "HDTX", year);

                      return (
                        <div className="card" key={index}>
                          <div className="card-header" id={"hdtx-heading-"+index}>
                            <a
                              href="#"
                              className="btn btn-link show collapsed"
                              data-toggle="collapse"
                              data-target={"#collapse-hdtx-"+index}
                              aria-expanded="false"
                              aria-controls={"collapse-hdtx-"+index}
                            >
                              {year}
                            </a>
                          </div>
                          <div
                            id={"collapse-hdtx-"+index}
                            className="collapse"
                            aria-labelledby={"hdtx-heading-"+index}
                            data-parent="#accordion-hdtx"
                          >
                            <div className="card-body">
                              {
                                files.map((file, fileIndex) => {
                                  var indexFileName = file.default.lastIndexOf('/');
                                  var indexfixedName = file.default.indexOf('.');
                                  var name = file.default.substring(indexFileName + 1, indexfixedName);
                                  return (
                                    <div className="row" key={fileIndex}>
                                      <div className="col col-12 file-wrapper">
                                        <Link to={file.default} target="_blank" download>
                                          <div className="file-icon">
                                            <span>
                                              <i
                                                className="fa fa-file-pdf-o fa-3x"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                          </div>
                                          <div className="file-name ml-3">
                                            {name}.pdf
                                          </div>
                                        </Link>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <h2>PT Panasia Filament Inti Tbk.</h2>
              <div className="downloadable-accordian-wrapeer">
                <div id="accordion-pafi">
                  {
                    yearlyReport.map((year, index) => 
                    {
                      var files = getPDFs(pdfs, "PAFI", year);

                      return (
                        <div className="card" key={index}>
                          <div className="card-header" id={"pafi-heading-"+index}>
                            <a
                              href="#"
                              className="btn btn-link show collapsed"
                              data-toggle="collapse"
                              data-target={"#collapse-pafi-"+index}
                              aria-expanded="false"
                              aria-controls={"collapse-pafi-"+index}
                            >
                              {year}
                            </a>
                          </div>
                          <div
                            id={"collapse-pafi-"+index}
                            className="collapse"
                            aria-labelledby={"pafi-heading-"+index}
                            data-parent="#accordion-pafi"
                          >
                            <div className="card-body">
                              {
                                files.map((file, fileIndex) => {
                                  var indexFileName = file.default.lastIndexOf('/');
                                  var indexfixedName = file.default.indexOf('.');
                                  var name = file.default.substring(indexFileName + 1, indexfixedName);
                                  return (
                                    <div className="row" key={fileIndex}>
                                      <div className="col col-12 file-wrapper">
                                        <Link to={file.default} target="_blank" download>
                                          <div className="file-icon">
                                            <span>
                                              <i
                                                className="fa fa-file-pdf-o fa-3x"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                          </div>
                                          <div className="file-name ml-3">
                                            {name}.pdf
                                          </div>
                                        </Link>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Downloadable;
